import {
    AdPlacementType,
    AdProps,
    AdType,
    ArticleAdProps,
    ComponentType,
    LayoutComponentProps,
    LayoutProps,
    MetaDataProps,
} from '@sport1/types/web'

export const outbrainAds: AdType[] = [AdType.OUTBRAIN_AD, AdType.OUTBRAIN_CAROUSEL_AD]

export const outbrainMobileAds: string[] = ['MB_17']

export const allowedPlacements = {
    desktop: ['banner', 'sky', 'posterad', 'rectangle', 'teaser'],
    mobile: ['topmobile', 'stickyfooter', 'mobile_teaser'],
}

export enum AdPlatform {
    BOTH = 'both',
    MOBILE = 'mobile',
    WEB = 'web',
}

export type AdPlatformProps = {
    platform?: AdPlatform
}

type AdPositionProps = {
    position?: number
    /** Used to avoid covering other ads(sly_btf) */
    scrollHeight?: number
}

export type AdRenderProps = AdProps &
    AdPlatformProps &
    AdPositionProps & { mobileId?: string; desktopId?: string }

export type AdInfoProps = AdPlatformProps &
    AdPositionProps & {
        adType?: AdType
        placement?: AdPlacementType
        mobileId?: string
        desktopId?: string
    }

const meta: MetaDataProps = {
    tracking: [
        {
            key: 'component_type',
            value: 'AD',
        },
    ],
}

export const createAd = ({
    placement = AdPlacementType.MREC,
    adType = AdType.DISPLAY,
    position,
    platform = AdPlatform.BOTH,
    adKeys,
    scrollHeight = 0,
    mobileId,
    desktopId,
}: AdInfoProps & { adKeys?: string[] }): AdRenderProps => {
    const adRenderProps = {
        meta,
        type: ComponentType.AD,
        platform,
        ad: {
            placement,
            adType,
            adKeys,
        },
        componentKey: Math.round(Math.random() * 32000).toString(),
        ...(mobileId && { mobileId }),
        ...(desktopId && { desktopId }),
    } as AdRenderProps
    if (scrollHeight) {
        adRenderProps.scrollHeight = scrollHeight
    }
    return Object.assign(adRenderProps, typeof position === 'number' ? { position } : null)
}

export const createAdKeys = (metaDataProps?: MetaDataProps): string[] =>
    metaDataProps?.tracking?.reduce((accumulator: string[], trackingData) => {
        if (trackingData.key?.startsWith('page_adLevel')) {
            accumulator.push(trackingData.value)
        }
        return accumulator
    }, []) || []

export const addAdsToLayout = ({
    adInfos,
    layout,
    addTo,
}: {
    adInfos: AdInfoProps[]
    layout: LayoutProps
    addTo?: 'components' | 'article'
}): void => {
    if (adInfos.length && layout.components?.length) {
        const adKeys = createAdKeys(layout.meta)
        adInfos.forEach((ad: AdInfoProps) => {
            const generatedAd = createAd({ ...ad, adKeys })
            if (
                addTo !== 'components' &&
                layout.components[0]?.type === ComponentType.ARTICLE_SECTION
            ) {
                generatedAd.position !== undefined
                    ? layout.components[0].content.splice(
                          generatedAd.position,
                          0,
                          generatedAd as unknown as ArticleAdProps
                      )
                    : layout.components[0].content.push(generatedAd as unknown as ArticleAdProps)
            } else {
                generatedAd.position !== undefined
                    ? layout.components.splice(generatedAd.position, 0, generatedAd)
                    : layout.components.push(generatedAd)
            }
        })
    }
}

export const getNavigationChannelBarHeight = (): number | undefined => {
    if (typeof window !== 'undefined') {
        const el = document.querySelector('div[data-testid=Standard-Header-SportsNavBar]')
        return el?.clientHeight
    }
}

export const getNavigationSubChannelBarHeight = (): number | undefined => {
    if (typeof window !== 'undefined') {
        const el = document.querySelector('div[data-testid=TabBar-SubChannelTabBar-onyx]')
        return el?.parentElement?.parentElement?.parentElement?.clientHeight
    }
}

interface IRemoveLastAdsFromLayout {
    layoutData: LayoutProps
    adTypeToStay?: AdType
    leftAdsIfNoToStay?: boolean
    extraEndComponent?: LayoutComponentProps
}

export const removeLastAdsFromLayout = ({
    layoutData,
    adTypeToStay,
    leftAdsIfNoToStay,
    extraEndComponent,
}: IRemoveLastAdsFromLayout): LayoutProps => {
    const tempComponents = [...layoutData.components].reverse()
    const firstNoAdComponentIndex = tempComponents.findIndex(item => item.type !== 'AD')
    const removedAds = tempComponents.splice(0, firstNoAdComponentIndex)
    tempComponents.reverse()
    let adToStay: LayoutComponentProps[] = []
    if (adTypeToStay) {
        const foundAd = removedAds.find(
            item => item.type === 'AD' && item.ad.adType === adTypeToStay
        )
        if (!foundAd && leftAdsIfNoToStay) {
            adToStay = removedAds
        } else if (foundAd) {
            adToStay = [foundAd]
        }
    }
    return {
        ...layoutData,
        components: [
            ...tempComponents,
            ...adToStay,
            ...(extraEndComponent ? [extraEndComponent] : []),
        ],
    }
}
